import Link from 'next/link'
import { useEffect, useState } from 'react'
import {
  Chip,
  InputBase,
  List,
  ListItemButton,
  Popover,
  Stack,
  Typography
} from '@mui/material'
import {
  ArrowDropUp as ArrowDropUpIcon,
  ArrowDropDown as ArrowDropDownIcon
} from '@mui/icons-material'
import { useTranslation } from '@contexts/translation'

const SelectItem = ({
  option,
  nameField,
  selectedIndex,
  idx,
  handleClickItem
}) => {
  return (
    <ListItemButton
      sx={{
        '&.Mui-selected': {
          backgroundColor: '#0062FF14',
          // backgroundColor: 'unset',
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: 'unset'
            // backgroundColor: '#0062FF1F'
          }
        }
      }}
      selected={selectedIndex === idx}
      onClick={() => handleClickItem(option, idx)}
    >
      {option[nameField]}
    </ListItemButton>
  )
}

const TopViewSelector = ({
  label = null,
  options = [],
  nameField,
  defaultIndex = 0,
  labelField = null,
  searchBar = false,
  arrow = true
}) => {
  const { dictionary } = useTranslation()

  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedIndex, setSelectedIndex] = useState(defaultIndex)
  const [value, setValue] = useState('')
  const [optionsFiltered, setOptionsFiltered] = useState([])

  // intializor only runs once... need to update state with a useEffect
  useEffect(() => {
    setSelectedIndex(defaultIndex)
  }, [defaultIndex])

  useEffect(() => {
    if (!open) {
      setValue('')
    }
    if (value.length) {
      const valueLow = value.toLowerCase()
      setOptionsFiltered(
        options.filter(
          opt =>
            typeof opt[nameField] == 'string' &&
            opt[nameField]?.toLowerCase().includes(valueLow)
        )
      )
    } else {
      setOptionsFiltered(options)
    }
  }, [value, open, options])

  const _handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const _handleClickItem = (option, idx) => {
    setSelectedIndex(idx)
    option.onClick && option.onClick()
    setAnchorEl(null)
  }

  const _handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'more-actions-popover' : undefined

  return (
    <div>
      <Chip
        clickable
        aria-describedby={id}
        onClick={_handleClick}
        sx={{
          ml: 'auto',
          background: 'unset',
          '&:hover': {
            backgroundColor: '#0062FF1F'
          }
        }}
        label={
          <Stack direction="row" alignItems="center">
            {label ? (
              <Typography variant="button" color="white">
                {label}
              </Typography>
            ) : labelField && options[selectedIndex] ? (
              <Typography variant="button" color="white">
                {options[selectedIndex][labelField]}
              </Typography>
            ) : nameField && options[selectedIndex] ? (
              <Typography variant="button" color="white">
                {options[selectedIndex][nameField]}
              </Typography>
            ) : (
              <Typography variant="button" color="white"></Typography>
            )}
            {arrow &&
              (open ? (
                <ArrowDropUpIcon
                  sx={{ color: t => `${t.palette.elements.white} !important` }}
                />
              ) : (
                <ArrowDropDownIcon
                  sx={{ color: t => `${t.palette.elements.white} !important` }}
                />
              ))}
          </Stack>
        }
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={_handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <List sx={{ minWidth: '200px' }}>
          {searchBar && (
            <InputBase
              sx={{ ml: 2, my: 1, flex: 1 }}
              placeholder={dictionary.search}
              value={value}
              onChange={e => setValue(e.target.value)}
            />
          )}
          {optionsFiltered?.map((o, i) => {
            if (!o.isHidden)
              return o.link ? (
                <Link key={i} href={o.link}>
                  <a>
                    <SelectItem
                      option={o}
                      idx={i}
                      nameField={nameField}
                      selectedIndex={selectedIndex}
                      handleClickItem={_handleClickItem}
                    />
                  </a>
                </Link>
              ) : (
                <SelectItem
                  key={i}
                  option={o}
                  idx={i}
                  nameField={nameField}
                  selectedIndex={selectedIndex}
                  handleClickItem={_handleClickItem}
                />
              )
          })}
        </List>
      </Popover>
    </div>
  )
}

export default TopViewSelector
