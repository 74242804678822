import { useRouter } from 'next/router'
import Link from 'next/link'
import { Divider, MenuItem, MenuList, Paper } from '@mui/material'
import {
  Build,
  Storage,
  Person,
  Groups,
  Logout,
  CreditCard,
  CorporateFare
} from '@mui/icons-material'

import routes from '@config/routes'
import { useAuth } from '@contexts/auth'
import { checkPerms } from '@utils/checkPerms'
import { useTranslation } from '@contexts/translation'

import useStyles from './popoverMenuStyles'

const PopoverMenu = ({ setIsSwitchingCompany, switchCompany }) => {
  const router = useRouter()
  const classes = useStyles()
  const { dictionary } = useTranslation()
  const { organization, permissions, logout, user } = useAuth()
  const hasPerm = checkPerms({
    is_admin: true,
    org: organization,
    perms: permissions
  })

  const path = router.pathname

  const handleSwitchCompany = () => setIsSwitchingCompany(true)

  return (
    <Paper className={classes.menu}>
      <MenuList
        className={classes.menuContentList}
        data-cy={'user-settings-menu'}
      >
        {user.role === 'tenant' ? (
          <>
            <Link href={routes.account.profile.info}>
              <a>
                {/* Profile */}
                <MenuItem
                  classes={{
                    selected: classes.menuSelected
                  }}
                  selected={path.includes(routes.account.profile.root)}
                >
                  <Person className={classes.menuIcon} />
                  {dictionary.profile}
                </MenuItem>
              </a>
            </Link>

            <MenuItem onClick={logout}>
              <Logout className={classes.menuIcon} />
              {dictionary.logout}
            </MenuItem>
          </>
        ) : (
          <>
            {organization && (
              <div>
                <Link href={routes.account.profile.info}>
                  <a>
                    {/* Profile */}
                    <MenuItem
                      classes={{
                        selected: classes.menuSelected
                      }}
                      selected={path.includes(routes.account.profile.root)}
                    >
                      <Person className={classes.menuIcon} />
                      {dictionary.profile}
                    </MenuItem>
                  </a>
                </Link>

                {/* Settings */}
                {hasPerm && (
                  <Link href={routes.account.settings.general.organization}>
                    <a>
                      <MenuItem
                        classes={{
                          selected: classes.menuSelected
                        }}
                        selected={path.includes(routes.account.settings.root)}
                      >
                        <Build className={classes.menuIcon} />
                        {dictionary.settings}
                      </MenuItem>
                    </a>
                  </Link>
                )}

                {/* Data */}
                {hasPerm && (
                  <Link href={routes.account.data.exports}>
                    <a>
                      <MenuItem
                        classes={{
                          selected: classes.menuSelected
                        }}
                        selected={path.includes(routes.account.data.root)}
                      >
                        <Storage className={classes.menuIcon} />
                        {dictionary.data}
                      </MenuItem>
                    </a>
                  </Link>
                )}

                {/* Users */}
                {hasPerm && (
                  <Link href={routes.account.users.users}>
                    <a>
                      <MenuItem
                        classes={{
                          selected: classes.menuSelected
                        }}
                        selected={path.includes(routes.account.users.root)}
                      >
                        <Groups className={classes.menuIcon} />
                        {dictionary.users}
                      </MenuItem>
                    </a>
                  </Link>
                )}

                {/* Subscription */}
                {hasPerm && (
                  <Link href={routes.account.subscription}>
                    <a>
                      <MenuItem
                        classes={{
                          selected: classes.menuSelected
                        }}
                        selected={path.includes(routes.account.subscription)}
                      >
                        <CreditCard className={classes.menuIcon} />
                        {dictionary.subscription}
                      </MenuItem>
                    </a>
                  </Link>
                )}

                <Divider />

                {/* Switch company */}
                {switchCompany && (
                  <>
                    <MenuItem onClick={handleSwitchCompany}>
                      <CorporateFare className={classes.menuIcon} />
                      {dictionary.switch_company}
                    </MenuItem>

                    <Divider />
                  </>
                )}
              </div>
            )}

            <Divider />

            {/* Logout */}
            <MenuItem onClick={logout} data-cy={'logout-menu-link'}>
              <Logout className={classes.menuIcon} />
              {dictionary.logout}
            </MenuItem>
          </>
        )}
      </MenuList>
    </Paper>
  )
}

export default PopoverMenu
