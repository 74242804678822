import Link from 'next/link'
import Typography from '@mui/material/Typography'

import { useFetchData } from '@api/fetchData'
import API_URL from '@config/services'
import {
  Stack,
  IconButton,
  ListItemText,
  List,
  Grid,
  Chip,
  ListItemButton,
  ListItemIcon
} from '@mui/material'
import { useTranslation } from '@contexts/translation'
// import { useAuth } from '@contexts/auth'
import { ArrowForwardIos } from '@mui/icons-material'
import routes from '@config/routes'
import queryString from '@utils/queryString'
// import queryString from '@utils/queryString'

const Alerts = ({ items = [], id }) => {
  const { dictionary } = useTranslation()

  return (
    <Stack spacing={0.5}>
      {items &&
        items.map(item => {
          const itemLink =
            item.type === 'planning'
              ? `${routes.project.timeline.root}/${id}`
              : item.type === 'invoicing'
              ? `${routes.project.invoicing.summary}/${id}`
              : item.type === 'estimates'
              ? `${routes.project.costs.estimates}/${id}`
              : item.type === 'budget'
              ? `${routes.project.timeline.budget}/${id}`
              : item.type === 'fees'
              ? `${routes.project.fees.summary}/${id}`
              : item.type === 'timeline'
              ? `${routes.project.timeline.root}/${id}`
              : `${routes.project.summary}/${id}`
          return (
            <Link href={itemLink} passHref key={item.name}>
              <a>
                <ListItemButton
                  sx={{
                    py: 0,
                    px: 0.5,
                    border: theme => `1px solid ${theme.palette.elements.main}`,
                    borderRadius: theme => theme.spacing(1)
                  }}
                >
                  <ListItemText
                    primary={
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Chip
                          size="small"
                          label={item.type_display || dictionary.alert}
                          sx={{
                            bgcolor: t => t.palette.error.light,
                            color: t => t.palette.error.main
                          }}
                        />
                        <Typography variant="subtitle2">
                          {item.message}
                        </Typography>
                      </Stack>
                    }
                  />
                  <ListItemIcon sx={{ minWidth: '8px' }}>
                    <ArrowForwardIos
                      color="primary"
                      sx={{ width: 15, height: 15 }}
                    />
                  </ListItemIcon>
                </ListItemButton>
              </a>
            </Link>
          )
        })}
    </Stack>
  )
}

const AlertsList = ({ items = [], projectId, type }) => {
  const { dictionary } = useTranslation()

  const itemLink =
    type === 'lease'
      ? `${routes.project.leases.root}/${projectId}`
      : type === 'task'
      ? `${routes.project.collaboration.tasks}/${projectId}`
      : `${routes.project.summary}/${projectId}`

  return (
    <Stack spacing={1.5}>
      {items.map(item => (
        <Link href={itemLink} passHref key={item.name}>
          <a>
            <ListItemButton
              sx={{
                py: 0.5,
                px: 1,
                border: theme => `1px solid ${theme.palette.elements.main}`,
                borderRadius: theme => theme.spacing(1)
              }}
            >
              <ListItemText
                primary={
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Chip
                      size="small"
                      label={item.type_display || dictionary.alert}
                      sx={{
                        bgcolor: t => t.palette.error.light,
                        color: t => t.palette.error.main
                      }}
                    />
                    <Typography variant="subtitle2">{item.message}</Typography>
                  </Stack>
                }
              />
              <ListItemIcon sx={{ minWidth: '8px' }}>
                <ArrowForwardIos
                  color="primary"
                  sx={{ width: 15, height: 15 }}
                />
              </ListItemIcon>
            </ListItemButton>
          </a>
        </Link>
      ))}
    </Stack>
  )
}

const AlertProjects = ({ projectId = null }) => {
  const { dictionary } = useTranslation()

  const { data: alerts } = useFetchData(API_URL.ALERTS.GET_ALERTS())

  const { data: tasksAlerts } = useFetchData(
    projectId ? API_URL.ALERTS.DUE_TASKS(projectId) : null
  )

  const { data: expiringLeasesAlerts } = useFetchData(
    projectId
      ? API_URL.ALERTS.EXPIRING_LEASES() +
          '?' +
          queryString({ operation: projectId })
      : API_URL.ALERTS.EXPIRING_LEASES()
  )

  if (!alerts?.length && !expiringLeasesAlerts?.length) {
    return (
      <Grid item xs={12}>
        <Typography variant="body1" color="text.secondary">
          {dictionary.no_alerts}
        </Typography>
      </Grid>
    )
  }

  return (
    <Grid container spacing={1}>
      {alerts?.length &&
        alerts.flatMap(p => (
          <Stack key={p.id} mb={1} width={'100%'}>
            {!projectId && (
              <Stack direction="row" alignItems="center">
                <Typography variant="body1" color="text.secondary">
                  {p.display}
                </Typography>
                <Link href={`${routes.project.summary}/${p.id}`} passHref>
                  <IconButton size="small">
                    <ArrowForwardIos sx={{ width: 15, height: 15 }} />
                  </IconButton>
                </Link>
              </Stack>
            )}
            <List dense={true}>
              <Alerts items={p.alerts.alerts} id={p.id} />
            </List>
          </Stack>
        ))}

      {expiringLeasesAlerts?.length > 0 && (
        <>
          <Grid item xs={12} mt={2}>
            <Typography variant="subtitle1" color="text.secondary">
              {dictionary.leases}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <List dense={true}>
              <AlertsList
                items={expiringLeasesAlerts}
                projectId={projectId}
                type={'lease'}
              />
            </List>
          </Grid>
        </>
      )}

      {projectId && tasksAlerts?.length > 0 && (
        <>
          <Grid item xs={12} mt={2}>
            <Typography variant="subtitle1" color="text.secondary">
              {dictionary.tasks}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <List dense={true}>
              <AlertsList
                items={tasksAlerts}
                projectId={projectId}
                type={'task'}
              />
            </List>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default AlertProjects
