import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'

import Typography from '@mui/material/Typography'

import { Chip, ListItemIcon, Stack } from '@mui/material'
import { subDays } from 'date-fns'
import { formatDistance } from '@utils/date-fns_functions'
import useChipStyles from '@styles/chip/chip'
import { useTranslation } from '@contexts/translation'
import Link from 'next/link'
import routes from '@config/routes'

const NotifItem = ({ notif, secondaryAction }) => {
  const { lang } = useTranslation()
  const classesChip = useChipStyles()

  const getChipColor = (value, unread) => {
    if (unread) {
      if (value === 'info') return classesChip.secondaryLight
      if (value === 'success') return classesChip.successLight
      if (value === 'warning') return classesChip.warningLight
      if (value === 'error') return classesChip.errorLight
    }
  }

  const link =
    notif?.verb === 'expense_group_submitted'
      ? routes.costs.my_expenses
      : notif?.verb === 'expense_reports_validations'
      ? routes.costs.my_expenses
      : notif?.verb === 'expense_reports_reimbursal'
      ? routes.costs.my_expenses
      : notif?.verb === 'expense_reports_submissions'
      ? routes.costs.my_expenses
      : notif?.verb === 'time_off_submissions'
      ? routes.times.time.timeoff.root
      : notif?.verb === 'time_off_validations'
      ? routes.times.time.timeoff.root
      : notif?.verb === 'time_off_requests'
      ? routes.times.time.timeoff.root
      : notif?.verb === 'time_off_refusals'
      ? routes.times.time.timeoff.root
      : notif?.verb === 'time_entry_refusals'
      ? routes.times.time.root
      : notif?.verb === 'invoices_sent'
      ? routes.invoicing.invoices
      : notif?.verb === 'invoices_paid'
      ? routes.invoicing.invoices
      : notif?.verb === 'post_posted'
      ? routes.collaboration.blog
      : notif?.verb === 'job_invoices_created'
      ? routes.costs.estimates
      : notif?.verb === 'job_invoices_validated'
      ? routes.costs.estimates
      : notif?.verb === 'job_invoices_paid'
      ? routes.costs.estimates
      : notif?.verb === 'invitations_accepted'
      ? routes.account.users.users
      : ''

  return (
    <ListItem
      key={notif.id}
      secondaryAction={secondaryAction}
      sx={{
        py: 0,
        pl: 0.5,
        border: theme => `1px solid ${theme.palette.elements.main}`,
        borderRadius: theme => theme.spacing(1),
        margin: theme => `${theme.spacing(1)} 0`,
        '&:last-child': { mb: 0 },
        '&:first-of-type': { mt: 0 }
      }}
    >
      <ListItemIcon>
        <Chip
          size="small"
          className={getChipColor(notif.level, notif.unread)}
          label={notif.level}
        />
      </ListItemIcon>
      <ListItemText
        primary={
          <Stack>
            <Typography
              variant="caption"
              color="primary"
              sx={{ cursor: 'pointer' }}
            >
              <Link href={link} passHref>
                {notif.description}
              </Link>
            </Typography>
            <Typography variant="caption">
              {formatDistance(
                subDays(new Date(notif.timestamp), 0),
                new Date(),
                lang
              )}
            </Typography>
          </Stack>
        }
      />
    </ListItem>
  )
}

export default NotifItem
