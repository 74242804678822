import {
  Grid,
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
  DialogContent
} from '@mui/material'
import { Close } from '@mui/icons-material'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles({ dialog: { alignItems: 'flex-start' } })

const CommonModal = ({
  title,
  open,
  onClose,
  maxWidth = 'lg',
  children,
  noPadding = false,
  isAlignTop = false
}) => {
  const classes = useStyles()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={isAlignTop ? { container: classes.dialog } : {}}
      fullWidth
      maxWidth={maxWidth}
    >
      <DialogTitle
        component="div"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: t => t.palette.secondary.light
        }}
        p={3}
      >
        <Typography variant="h6" color="secondary.dark">
          {title}
        </Typography>
        <IconButton
          sx={{ color: t => t.palette.secondary.dark }}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        {noPadding && children}
        {!noPadding && (
          <Grid container padding={3}>
            {children}
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default CommonModal
