import { useRouter } from 'next/router'
import { createContext, useContext, useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import client from '@config/client'
import routes from '@config/routes'

import {
  getMyProfile,
  // getRefreshToken,
  getMyOrganization,
  // onRefreshToken,
  getAllOrganizations,
  getMyPermissions,
  getOrgUser
} from '@api/auth'
import { obtain2faToken, unverify2fa } from '@api/profiles'

import Loader from '@components/common/Loader/Loader'
import { getOrganization } from '@api/organizations'
import { getTeam } from '@api/teams'
// import { format } from '@utils/date-fns_functions'

const AuthContext = createContext()

const isProtectedRoute = path => {
  if (
    path === routes.account.login ||
    path === routes.account.loginOTP ||
    path === routes.account.signup ||
    path.includes(routes.account.resetPassword) ||
    path.includes(routes.account.activation) ||
    path.includes(routes.account.registration) ||
    path.includes(routes.account.acceptInvitation)
    // path.includes('/accept_invite')
  ) {
    return false
  } else {
    return true
  }
}

export const AuthProvider = ({ children }) => {
  const router = useRouter()
  const [user, setUser] = useState(null)
  const [organization, setOrganization] = useState(null)
  const [viewAs, setViewAs] = useState(null)
  const [organizationTeam, setOrganizationTeam] = useState(null)
  const [profilePreferences, setProfilePreferences] = useState(null)
  const [permissions, setPermissions] = useState(null)
  const [orgSettings, setOrgSettings] = useState(null)
  const [teamSettings, setTeamSettings] = useState(null)
  const [membership, setMembership] = useState(null)
  const [isFinnishedStepClicked, setIsFinnishedStepClicked] = useState(false)

  const [update, setUpdate] = useState(false)
  const [loading, setLoading] = useState(true)

  const redirectToCreateOrganization = async () => {
    setLoading(true)
    const organizationInfo = await getMyOrganization()
    const organizations = await getAllOrganizations()

    if (organizationInfo) {
      // const isOrganizationActive = organizationInfo.selected_org
      const isOrganizationActive =
        organizationInfo && organizationInfo.selected_org
          ? organizationInfo
          : organizations?.data?.organizations[0]

      if (organizations) {
        const _orguserInfo = organizations?.data?.organizations?.find(
          o => o.id == organizationInfo.selected_org
        )
        if (_orguserInfo) {
          const orguserInfo = _orguserInfo.orguser
          setMembership({
            orguser: orguserInfo.pk,
            username: organizations.data.username,
            first_name: orguserInfo.first_name,
            last_name: orguserInfo.last_name,
            has_access: orguserInfo.has_access,
            is_staff: organizationInfo?.is_staff,
            is_readonly: false
          })
        }
      }

      if (!isOrganizationActive && !organizationInfo?.domain_exists) {
        return null
      } else if (!isOrganizationActive && organizationInfo.domain_exists) {
        return organizationInfo
      } else {
        return isOrganizationActive
      }
    }
  }

  const tenantSetup = async () => {
    const org = await redirectToCreateOrganization()

    if (org && org.selected_org) {
      setOrganization(org.selected_org)
      setOrganizationTeam(org?.selected_team)
      setProfilePreferences({
        locale: org.locale
      })

      if (org.selected_team) {
        const teamInfo = await getTeam(org.selected_team)
        if (teamInfo) setTeamSettings(teamInfo.data)
      }
      setLoading(false)

      router.push(routes.tenant.root)
    }
  }

  const setParameters = async (fromLogin = false, usr) => {
    const org = await redirectToCreateOrganization()
    if (org && org.selected_org) {
      setOrganization(org.selected_org)
      setOrganizationTeam(org?.selected_team)
      setProfilePreferences({
        locale: org.locale
      })

      if (org.selected_team) {
        const teamInfo = await getTeam(org.selected_team)
        if (teamInfo) setTeamSettings(teamInfo.data)
      }
      setLoading(false)
      if (fromLogin) router.push(routes.root) // Redirect to /dashboard
    } else {
      if (fromLogin && !org?.domain_exists) {
        setLoading(false)
        router.push({
          pathname: routes.account.company,
          query: { email: user ? user.email : usr?.email }
        })
      } else if (fromLogin && org?.domain_exists) {
        setLoading(false)
        router.push(routes.account.domainExists)
      }
    }
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const view_as = queryParams.get('view_as') || Cookies.get('view_as')
    if (view_as) {
      if (view_as === 'null') {
        Cookies.remove('view_as')
        Cookies.remove('view_as_display')
        setViewAs(null)
      } else {
        Cookies.set('view_as', view_as)
        setViewAs(Cookies.get('view_as_display'))
      }
    } else setViewAs(null)

    async function loadUserFromCookies() {
      const token = Cookies.get('accessToken')
      // const refreshToken = Cookies.get('refreshToken')
      if (token) {
        client.defaults.headers.Authorization = `JWT ${token}`
        const response = await getMyProfile()

        if (Cookies.get('view_as')) {
          const responsePermission = await getMyPermissions()
          setPermissions(responsePermission)
        }

        if (response) {
          if (response.status === 401) {
            // const newToken = await onRefreshToken(refreshToken)
            // if (
            //   newToken &&
            //   newToken.status !== 401 &&
            //   newToken.status !== 400
            // ) {
            //   Cookies.set('accessToken', newToken.data.access, {
            //     expires: 60 * 60 * 14 // 12 hours
            //   })
            //   Cookies.set('refreshToken', newToken.data.refresh, {
            //     expires: 60 * 60 * 24 * 6 // 6 days
            //   })
            //   const response = await getMyProfile()
            //   const responsePermission = await getMyPermissions()
            //   setPermissions(responsePermission)

            //   setUser(response.data)
            //   await setParameters()
            // } else {
            Cookies.remove('accessToken')
            Cookies.remove('refreshToken')
            setUser(null)
            setOrganization(null)
            delete client.defaults.headers.Authorization
            router.push(routes.account.login)
            // }
          } else {
            const { data: orgUser } = await getOrgUser()
            if (orgUser.tenant) {
              setUser({
                ...response.data,
                role: 'tenant',
                tenantId: orgUser.tenant?.id
              })
            } else {
              setUser(response.data)
            }
            await setParameters()
          }
        }
      }
      setLoading(false)
    }
    loadUserFromCookies()
  }, [])

  const updateOrganization = () => setUpdate(true)

  useEffect(() => {
    const organizationCheck = async () => await setParameters()
    if (update) {
      organizationCheck()
      setUpdate(false)
    }
  }, [update])

  const _setTeamSettings = async () => {
    const teamInfo = await getTeam(organizationTeam)
    if (teamInfo) setTeamSettings(teamInfo.data)
  }

  const _setOrgSettings = async () => {
    const response = await getOrganization(organization)

    if (response?.status === 200) {
      const org = response.data
      // console.log('org', org)
      setOrgSettings({
        pk: org.pk,
        created_at: org.created_at,
        name: org.name,
        currency: org.currency,
        currency_symbol: org.currency_symbol,
        country: org.country,
        // is_demo: org.is_demo,
        // is_trial: org.is_trial,
        can_add_users: org.can_add_users,
        finance_enabled: org.finance_enabled,
        is_active_subscription: org.is_active_subscription,
        slug: org.slug
      })
    }
  }

  useEffect(() => {
    if (organization) _setOrgSettings()
  }, [organization])

  const login = async (response, data) => {
    Cookies.set('accessToken', response.data.access, {
      expires: data?.is_remember ? 60 : 60
    })
    // Cookies.set('refreshaccess', response.data.refresh)
    client.defaults.headers.Authorization = `JWT ${response.data.access}`
    const organization = await getMyOrganization()
    if (organization?.two_factors_auth_activated) {
      await obtain2faToken()
      router.push(routes.account.loginOTP)
    } else await finishLogin()
  }

  const finishLogin = async () => {
    setLoading(true)

    const { data: user } = await getMyProfile()
    const { data: orgUser } = await getOrgUser()
    if (orgUser.tenant) {
      setUser({ ...user, role: 'tenant', tenantId: orgUser.tenant.id })
    } else {
      setUser(user)
    }
    const responsePermission = await getMyPermissions()
    setPermissions(responsePermission)

    // await setParameters(true, user)

    if (orgUser.tenant) {
      await tenantSetup()
    } else {
      await setParameters(true, user)
    }
  }

  const logout = async () => {
    Cookies.remove('accessToken')
    // Cookies.remove('refreshToken')
    if (profilePreferences?.two_factors_auth_activated) await unverify2fa()
    setUser(null)
    setOrganization(null)
    setOrganizationTeam(null)
    setProfilePreferences(null)
    delete client.defaults.headers.Authorization
    router.push(routes.account.login)
  }

  useEffect(() => {
    if (user?.role === 'tenant') {
      router.push(routes.tenant.root)
    }
  }, [user])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated:
          !!user &&
          ((profilePreferences?.two_factors_auth_activated &&
            profilePreferences?.two_factors_auth_verified) ||
            !profilePreferences?.two_factors_auth_activated),
        user,
        organization,
        setOrganization,
        organizationTeam,
        setOrganizationTeam,
        orgSettings,
        teamSettings,
        membership,
        permissions,
        loading,
        login,
        finishLogin,
        logout,
        updateOrganization,
        _setOrgSettings,
        _setTeamSettings,
        profilePreferences,
        setProfilePreferences,
        viewAs,
        isFinnishedStepClicked,
        setIsFinnishedStepClicked
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)

export const ProtectRoute = ({ children }) => {
  const router = useRouter()
  const { isAuthenticated, loading, organization, orgSettings } = useAuth()

  const setIsDomainExist = async () => {
    const org = await getMyOrganization()
    if (org?.domain_exists) {
      router.push(routes.account.domainExists)
    } else {
      router.push(routes.account.company)
    }
  }
  if (loading) {
    return <Loader />
  }

  if (!isAuthenticated && router.pathname === routes.root) {
    router.push(routes.account.login)
    return <Loader />
  }

  if (
    !isAuthenticated &&
    isProtectedRoute(router.pathname) &&
    router.pathname !== routes.root
  ) {
    router.push(routes.account.login)
    return <Loader />
  }
  if (
    !loading &&
    isAuthenticated &&
    !organization &&
    router.pathname !== routes.account.company &&
    router.pathname !== routes.account.domainExists
  ) {
    setIsDomainExist()

    return <Loader />
  }

  if (isAuthenticated && !isProtectedRoute(router.pathname)) {
    router.push(routes.root)
    return <Loader />
  }

  const allowedURLs =
    router.asPath === routes.account.subscription ||
    router.asPath === routes.account.data.exports ||
    router.asPath === routes.root ||
    router.asPath === routes.account.login ||
    router.asPath === routes.account.company ||
    router.asPath === routes.account.domainExists ||
    router.asPath === routes.account.acceptInvitation ||
    router.asPath === routes.account.activation ||
    router.asPath === routes.account.loginOTP ||
    router.asPath === routes.account.onboarding ||
    router.asPath === routes.account.resetPassword ||
    router.asPath === routes.account.registration
  const redirectToSubscription =
    orgSettings &&
    false && // add is_active_subscription
    !allowedURLs

  if (redirectToSubscription) {
    router.push(routes.account.subscription)
    return <Loader />
  }
  return children
}
