import React from 'react'

import {
  Box,
  Toolbar,
  List,
  Chip,
  ListItemButton,
  Skeleton
} from '@mui/material'
import {
  Group,
  // Help,
  // VerticalSplit,
  // FindInPage,
  Description,
  Timeline,
  // AccountBalance,
  SpeakerNotes,
  ListAlt,
  Contacts,
  // Gesture,
  ContentPaste,
  // AccountBalanceWallet,
  Receipt,
  ReceiptLong,
  NotificationImportant,
  Approval,
  Circle,
  CalendarMonthOutlined,
  Equalizer,
  ConfirmationNumber,
  BusinessCenter
  // People
} from '@mui/icons-material'

import routes from '@config/routes'
import { useTranslation } from '@contexts/translation'

import useStyles from '@components/Header/styles'
import {
  CollapsableMenuItems,
  MenuItem
} from '@components/common/Menu/MenuItem'
import { useFetchData } from '@api/fetchData'
import API_URL from '@config/services'
import { useAuth } from '@contexts/auth'
import { checkPerms } from '@utils/checkPerms'
import { MenuTitle } from '.'
import useChipStyles from '@styles/chip/chip'
import Link from 'next/link'
import queryString from '@utils/queryString'

const MenuProfilePicture = () => {
  const { organization } = useAuth()
  const { data: orgInfo, isLoading } = useFetchData(
    API_URL.ORGANIZATIONS.GET_ORGANIZATION(organization)
  )

  return (
    <ListItemButton
      sx={{
        mx: theme => theme.spacing(5),
        my: theme => theme.spacing(2),
        padding: 0,
        justifyContent: { xs: 'center', md: 'flex-start' }
      }}
    >
      {isLoading && (
        <Skeleton width={'100%'} height={'100px'} variant="rectangular">
          <div />
        </Skeleton>
      )}
      <Box sx={{ width: 400 }}>
        {!isLoading && orgInfo && orgInfo.logo && (
          <Link href={routes.root} passHref>
            <Box
              sx={{
                cursor: 'pointer',
                height: '250px',
                borderRadius: theme => theme.spacing(1),
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundImage: `url(${orgInfo.logo})`
              }}
            />
          </Link>
        )}
      </Box>

      {!isLoading && orgInfo && !orgInfo.logo && (
        <Link href={routes.account.settings.general.organization} passHref>
          <Box
            component="img"
            src={'/Placeholders/logo_placeholder.png'}
            sx={{ width: 160 }}
          />
        </Link>
      )}
    </ListItemButton>
  )
}

const DrawerContent = ({ opportunitiesIDs, handleDrawerToggle }) => {
  const classes = useStyles()
  const classesChip = useChipStyles()
  const { dictionary } = useTranslation()
  const {
    isAuthenticated,
    organization,
    orgSettings,
    organizationTeam,
    permissions,
    membership
  } = useAuth()

  const { data: badgeCounts } = useFetchData(
    isAuthenticated && organizationTeam
      ? API_URL.ORG_USER.GET_VALIDATION_METRICS + '?team=' + organizationTeam
      : null
  )

  const routesMutable =
    API_URL.ORG_USER.GET_ORG_USER(membership?.orguser, organizationTeam) +
    '&' +
    queryString({ with_tags: true, with_custom_fields: true })
  const { data: orgUser } = useFetchData(
    membership?.orguser ? routesMutable : null
  )

  // const is_admin = checkPerms({
  //   is_admin: true,
  //   org: organization,
  //   team: organizationTeam,
  //   perms: permissions
  // })

  const manage_worklogs = checkPerms({
    perm: ['manage_worklogs'],
    org: organization,
    team: organizationTeam,
    perms: permissions
  })
  const time_validation_enabled = orgUser?.time_validation_enabled
  const manage_timeoff_requests = checkPerms({
    perm: ['manage_timeoff_requests'],
    org: organization,
    team: organizationTeam,
    perms: permissions
  })
  const timeoff_validation_enabled = orgUser?.timeoff_validation_enabled
  // const create_expenses = checkPerms({
  //   perm: ['create_expenses'],
  //   org: organization,
  //   team: organizationTeam,
  //   perms: permissions
  // })
  // const manage_expenses = checkPerms({
  //   perm: ['manage_expenses'],
  //   org: organization,
  //   team: organizationTeam,
  //   perms: permissions
  // })
  const expenses_validation_enabled = orgUser?.expenses_validation_enabled
  const view_members = checkPerms({
    perm: ['view_members'],
    org: organization,
    team: organizationTeam,
    perms: permissions
  })
  const manage_members = checkPerms({
    perm: ['manage_members'],
    org: organization,
    team: organizationTeam,
    perms: permissions
  })
  const view_finance_analytics = checkPerms({
    perm: ['view_finance_analytics'],
    org: organization,
    team: organizationTeam,
    perms: permissions
  })
  const manage_costs = checkPerms({
    perm: ['manage_costs'],
    org: organization,
    team: organizationTeam,
    perms: permissions
  })
  const manage_payroll = checkPerms({
    perm: ['manage_payroll'],
    org: organization,
    team: organizationTeam,
    perms: permissions
  })
  const manage_freelancers = checkPerms({
    perm: ['manage_freelancers'],
    org: organization,
    team: organizationTeam,
    perms: permissions
  })
  // const view_pipelines = checkPerms({
  //   perm: ['view_pipelines'],
  //   org: organization,
  //   team: organizationTeam,
  //   perms: permissions
  // })

  const view_invoices = checkPerms({
    perm: ['view_invoices'],
    org: organization,
    team: organizationTeam,
    perms: permissions
  })
  const manage_invoices = checkPerms({
    perm: ['manage_invoices'],
    org: organization,
    team: organizationTeam,
    perms: permissions
  })
  /* to uncomment when API get invoices with view_invoices_projects & manage_invoices_projects permissions */
  const view_invoices_projects = checkPerms({
    perm: ['view_invoices'],
    org: organization,
    team: organizationTeam,
    perms: permissions,
    checkAllProjects: true
  })
  const manage_invoices_projects = checkPerms({
    perm: ['manage_invoices'],
    org: organization,
    team: organizationTeam,
    perms: permissions,
    checkAllProjects: true
  })

  const validationCounts = badgeCounts?.total || 0
  const hoursToValidate = badgeCounts?.team_counts?.timelogs > 0

  return (
    <>
      <Toolbar>
        <div className={classes.mobTool} onClick={handleDrawerToggle}>
          +
        </div>
      </Toolbar>
      <Box className={classes.list}>
        <List>
          <MenuProfilePicture />

          {/*
          {is_admin && (
            <MenuItem
              path={routes.account.onboarding}
              title={dictionary.setup}
              icon={<Help />}
              handleDrawerMobileClose={handleDrawerToggle}
            />
          )}
          */}

          <MenuTitle
            title={dictionary.general}
            needMargin={false}
            innerSideMenu={false}
          />
          {/*
          <MenuItem
            path={routes.root}
            title={dictionary.dashboard}
            icon={<VerticalSplit />}
            exactURLs={[routes.root]}
            handleDrawerMobileClose={handleDrawerToggle}
          />
        */}

          {/*
          {view_pipelines && (
            <CollapsableMenuItems
              section={'opportunities'}
              path={routes.opportunities.summary}
              title={dictionary.opportunities}
              icon={<FindInPage />}
              baseURLs={[routes.opportunities.root]}
              idList={opportunitiesIDs}
              handleDrawerMobileClose={handleDrawerToggle}
            />
          )}
          */}

          {/* PROJECTS */}
          {view_finance_analytics && (
            <CollapsableMenuItems
              section={'projects'}
              path={routes.projects.summary}
              title={dictionary.projects}
              icon={<Description />}
              baseURLs={[routes.project.root, routes.projects.root]}
              idList={opportunitiesIDs}
              handleDrawerMobileClose={handleDrawerToggle}
            />
          )}
          {!view_finance_analytics && (
            <MenuItem
              path={routes.projects.root}
              title={dictionary.projects}
              icon={<Description />}
              baseURLs={[routes.projects.root, routes.project.root]}
              handleDrawerMobileClose={handleDrawerToggle}
            />
          )}

          <MenuTitle title={dictionary.team} innerSideMenu={false} />

          {view_members && !manage_members && (
            <MenuItem
              path={routes.teams.collaborators}
              title={dictionary.collaborators}
              icon={<Group />}
              baseURLs={[routes.teams.root]}
              handleDrawerMobileClose={handleDrawerToggle}
            />
          )}

          {/*
          {(create_expenses || manage_expenses) && (
            <MenuItem
              path={routes.costs.my_expenses}
              title={dictionary.expense_reports}
              icon={<AccountBalanceWallet />}
              baseURLs={[routes.costs.my_expenses, routes.costs.expense_items]}
              handleDrawerMobileClose={handleDrawerToggle}
            />
          )}
          */}

          {(manage_worklogs ||
            manage_timeoff_requests ||
            time_validation_enabled ||
            timeoff_validation_enabled ||
            expenses_validation_enabled) && (
            <MenuItem
              path={
                manage_worklogs
                  ? routes.validation.expenses
                  : routes.validation.timeoff
              }
              title={dictionary.validation}
              icon={<Approval />}
              baseURLs={[routes.validation.root]}
              handleDrawerMobileClose={handleDrawerToggle}
              endornment={
                validationCounts > 0 ? (
                  <Chip
                    size="small"
                    label={validationCounts}
                    className={classesChip.warning}
                  />
                ) : hoursToValidate ? (
                  <Circle fontSize="small" color="warning" />
                ) : null
              }
            />
          )}

          <MenuTitle title={dictionary.management} innerSideMenu={false} />
          {manage_costs && (
            <MenuItem
              path={routes.contacts.root}
              title={dictionary.contacts}
              icon={<Contacts />}
              exactURLs={[
                routes.contacts.root,
                routes.contacts.suppliers,
                routes.contacts.tenants,
                routes.contacts.landlords
              ]}
              handleDrawerMobileClose={handleDrawerToggle}
            />
          )}
          {(manage_costs || manage_payroll || manage_freelancers) && (
            <CollapsableMenuItems
              section={'costs'}
              path={
                manage_costs ? routes.costs.summary : routes.costs.contractors
              }
              title={dictionary.costs.root}
              icon={<Timeline />}
              baseURLs={[routes.costs.root]}
              excludedURLs={[
                routes.costs.my_expenses,
                routes.costs.expense_items,
                routes.project.costs.root,
                routes.finances.root
              ]}
              handleDrawerMobileClose={handleDrawerToggle}
            />
          )}
          {(view_invoices ||
            view_invoices_projects ||
            manage_invoices ||
            manage_invoices_projects) && (
            <CollapsableMenuItems
              section={'invoices'}
              path={
                manage_invoices
                  ? routes.invoicing.summary
                  : routes.invoicing.invoices
              }
              title={dictionary.invoices}
              icon={<Receipt />}
              baseURLs={[routes.invoicing.root]}
              excludedURLs={[routes.project.root]}
              handleDrawerMobileClose={handleDrawerToggle}
            />
          )}

          <MenuItem
            path={routes.cashflow.root}
            title={dictionary.cashflow}
            icon={<ReceiptLong />}
            exactURLs={[routes.cashflow.root]}
            handleDrawerMobileClose={handleDrawerToggle}
          />

          <MenuItem
            path={routes.ticketing.root}
            title={dictionary.ticketing}
            icon={<ConfirmationNumber />}
            exactURLs={[routes.ticketing.root]}
            handleDrawerMobileClose={handleDrawerToggle}
          />

          <CollapsableMenuItems
            section={'reports'}
            path={routes.reports.root}
            title={dictionary.reports}
            icon={<ContentPaste />}
            baseURLs={[routes.reports.root]}
            handleDrawerMobileClose={handleDrawerToggle}
          />

          {orgSettings?.finance_enabled && (
            <>
              <MenuTitle title={dictionary.finance} innerSideMenu={false} />

              <MenuItem
                path={routes.investors.root}
                title={dictionary.investors}
                icon={<BusinessCenter />}
                exactURLs={[routes.investors.root]}
                handleDrawerMobileClose={handleDrawerToggle}
              />

              {manage_costs && (
                <MenuItem
                  path={routes.internal.root}
                  title={dictionary.internal}
                  icon={<Equalizer />}
                  exactURLs={[routes.internal.root]}
                  handleDrawerMobileClose={handleDrawerToggle}
                />
              )}
            </>
          )}

          <MenuTitle title={dictionary.collaboration} innerSideMenu={false} />

          <MenuItem
            path={routes.collaboration.tasks}
            title={dictionary.tasks}
            icon={<ListAlt />}
            exactURLs={[routes.collaboration.tasks]}
            handleDrawerMobileClose={handleDrawerToggle}
          />

          <MenuItem
            path={routes.collaboration.calendar}
            title={dictionary.calendar}
            icon={<CalendarMonthOutlined />}
            baseURLs={[routes.collaboration.calendar]}
            handleDrawerMobileClose={handleDrawerToggle}
          />

          {/* <MenuItem
            path={routes.collaboration.notes}
            title={dictionary.notes}
            icon={<Gesture />}
            baseURLs={[routes.collaboration.notes]}
            handleDrawerMobileClose={handleDrawerToggle}
          /> */}

          <MenuItem
            path={routes.collaboration.blog}
            title={dictionary.blog}
            icon={<SpeakerNotes />}
            baseURLs={[routes.collaboration.blog]}
            handleDrawerMobileClose={handleDrawerToggle}
          />

          <MenuItem
            path={routes.collaboration.notifications}
            title={dictionary.notifications}
            icon={<NotificationImportant />}
            exactURLs={[routes.collaboration.notifications]}
            handleDrawerMobileClose={handleDrawerToggle}
          />
        </List>
      </Box>
    </>
  )
}

export default DrawerContent
