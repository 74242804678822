import { useTranslation } from '@contexts/translation'
import { Typography } from '@mui/material'

const NoPermissionPage = () => {
  const { dictionary } = useTranslation()

  return (
    <div
      style={{
        width: '100%',
        height: '90vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Typography variant="h5">{dictionary.no_page_permission}</Typography>
    </div>
  )
}

export default NoPermissionPage
