export const responseHandler = ({
  response,
  snackbar,
  dictionary,
  callback,
  msgSuccess,
  callbackError,
  setError
}) => {
  if (
    response?.status === 200 ||
    response?.status === 201 ||
    response?.status === 204
  ) {
    callback && callback()
    msgSuccess && snackbar(msgSuccess, { variant: 'success' })
  } else {
    callbackError && callbackError()

    let errMessage = ''
    if (response?.data?.errors) {
      if (setError) {
        response?.data?.errors?.forEach(e =>
          setError(e.field, { message: e.message })
        )
      }
      errMessage = response?.data?.errors.map(e => e.message).join('\n')
    }
    if (response?.status === 400) {
      if (errMessage)
        snackbar(errMessage, {
          variant: 'warning',
          style: { whiteSpace: 'pre-wrap' },
          autoHideDuration: 5000
        })
      else
        snackbar(`${dictionary.error} - ${dictionary.bad_request}`, {
          variant: 'warning'
        })
    } else if (response?.status === 401)
      if (errMessage)
        snackbar(errMessage, {
          variant: 'warning',
          style: { whiteSpace: 'pre-wrap' },
          autoHideDuration: 5000
        })
      else
        snackbar(`${dictionary.error} - ${dictionary.not_authorized}`, {
          variant: 'warning'
        })
    else if (response?.status === 403)
      snackbar(`${dictionary.error} - ${dictionary.permission_error}`, {
        variant: 'warning'
      })
    else if (response?.status === 404)
      snackbar(`${dictionary.error} - ${dictionary.not_found}`, {
        variant: 'warning'
      })
    else snackbar(`${dictionary.server_error}`, { variant: 'error' })
  }
}
