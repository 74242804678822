import { DISPLAY_DESKTOPSUBMENU } from '@components/Header/Header.config'
import NavSubMenu from '@components/Header/NavbarSubMenu'
import routes from '@config/routes'
import { useTranslation } from '@contexts/translation'
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  Menu,
  Typography
} from '@mui/material'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useState } from 'react'

export const MenuItem = ({
  path = '',
  title,
  icon,
  baseURLs = [],
  exactURLs = [],
  excludedURLs = [],
  endornment,
  handleDrawerMobileClose = () => {},
  idList = [],
  margin = '0px'
}) => {
  const router = useRouter()

  const id = router.query.id || router.query.project_id

  let isSelected = false

  if (baseURLs?.length > 0)
    for (const url of baseURLs) {
      if (
        (url === routes.opportunities.root ||
          router.asPath == routes.opportunities.root) &&
        id &&
        idList?.includes(+id)
      )
        isSelected = true
      else if (url === routes.project.root && id && idList?.includes(+id)) {
        isSelected = false
      } else if (router.asPath.includes(url)) isSelected = true
    }
  else if (exactURLs?.length > 0)
    for (const url of exactURLs) {
      if (router.asPath === url) isSelected = true
    }

  if (excludedURLs?.length > 0)
    for (const url of excludedURLs) {
      if (router.asPath.includes(url)) isSelected = false
    }

  const closeMobileMenuHandler = () => handleDrawerMobileClose()

  return (
    <Link href={path}>
      <a>
        <ListItemButton
          onClick={() => {
            if (window.innerWidth < 1000) closeMobileMenuHandler()
          }}
          selected={isSelected}
          sx={{
            pl: margin,
            py: '8px',
            '&.Mui-selected': {
              '&:hover': { background: t => t.palette.secondary.light },
              background: t => t.palette.secondary.light,
              boxShadow: t => `inset -2px 0px 0px ${t.palette.secondary.main}`
            }
          }}
        >
          {icon && (
            <ListItemIcon
              sx={{
                minWidth: 'unset',
                pl: '28px',
                mr: '12px',
                color: isSelected ? 'secondary.main' : 'text.primary'
              }}
            >
              {icon}
            </ListItemIcon>
          )}

          <Typography
            variant={isSelected ? 'subtitle2' : 'body2'}
            color={isSelected ? 'secondary.main' : 'text.primary'}
          >
            {title}
          </Typography>

          {endornment && (
            <ListItemIcon
              sx={{
                minWidth: 'unset',
                pl: '40px',
                mr: '0',
                color: isSelected ? 'secondary.main' : 'text.primary'
              }}
            >
              {endornment}
            </ListItemIcon>
          )}
        </ListItemButton>
      </a>
    </Link>
  )
}

export const CollapsableMenuItems = ({
  path = '',
  title,
  icon,
  exactURLs,
  baseURLs,
  excludedURLs,
  idList = [],
  endornment,
  handleDrawerMobileClose = () => {},
  section = null
}) => {
  const router = useRouter()
  const { dictionary } = useTranslation()

  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const Mopen = Boolean(anchorEl)

  const handleMClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleMClose = () => {
    setAnchorEl(null)
  }

  const id = router.query.id || router.query.project_id

  let isSelected = false

  if (baseURLs?.length > 0)
    for (const url of baseURLs) {
      if (url === routes.opportunities.root && id && idList?.includes(+id))
        isSelected = true
      else if (url === routes.project.root && id && idList?.includes(+id)) {
        isSelected = false
      } else if (router.asPath.includes(url)) isSelected = true
    }
  else if (exactURLs?.length > 0)
    for (const url of exactURLs) {
      if (router.asPath === url) isSelected = true
    }

  if (excludedURLs?.length > 0)
    for (const url of excludedURLs) {
      if (router.asPath.includes(url)) isSelected = false
    }

  return (
    <>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <ListItemButton
          onClick={() => setOpen(!open)}
          selected={isSelected}
          sx={{
            pl: 0,
            py: '8px',
            '&.Mui-selected': {
              '&:hover': { background: t => t.palette.secondary.light },
              background: t => t.palette.secondary.light,
              boxShadow: t => `inset -2px 0px 0px ${t.palette.secondary.main}`
            }
          }}
        >
          {icon && (
            <ListItemIcon
              sx={{
                minWidth: 'unset',
                pl: '28px',
                mr: '12px',
                color: isSelected ? 'secondary.main' : 'text.primary'
              }}
            >
              {icon}
            </ListItemIcon>
          )}

          <Typography
            variant={isSelected ? 'subtitle2' : 'body2'}
            color={isSelected ? 'secondary.main' : 'text.primary'}
          >
            {title}
          </Typography>

          {endornment && (
            <ListItemIcon
              sx={{
                minWidth: 'unset',
                pl: '40px',
                mr: '0',
                color: isSelected ? 'secondary.main' : 'text.primary'
              }}
            >
              {endornment}
            </ListItemIcon>
          )}
        </ListItemButton>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div">
            {NavSubMenu?.[section ? section : title]?.length > 0 &&
              NavSubMenu?.[section ? section : title]?.map((item, i) => (
                <MenuItem
                  key={i}
                  path={item.link}
                  title={dictionary[`${item.display_name}`]}
                  exactURLs={item.exactURL ? [item.exactURL] : []}
                  baseURLs={item.exactURL ? [] : [item.link]}
                  handleDrawerMobileClose={handleDrawerMobileClose}
                  idList={idList}
                  margin={'40px'}
                />
              ))}
          </List>
        </Collapse>
      </Box>

      <Box
        sx={{
          display: { xs: 'none', md: DISPLAY_DESKTOPSUBMENU ? 'block' : 'none' }
        }}
      >
        <ListItemButton
          selected={isSelected}
          onClick={event => handleMClick(event, title)}
          sx={{
            pl: 0,
            py: '8px',
            '&.Mui-selected': {
              '&:hover': { background: t => t.palette.secondary.light },
              background: t => t.palette.secondary.light,
              boxShadow: t => `inset -2px 0px 0px ${t.palette.secondary.main}`
            }
          }}
        >
          {icon && (
            <ListItemIcon
              sx={{
                minWidth: 'unset',
                pl: '28px',
                mr: '12px',
                color: isSelected ? 'secondary.main' : 'text.primary'
              }}
            >
              {icon}
            </ListItemIcon>
          )}

          <Typography
            variant={isSelected ? 'subtitle2' : 'body2'}
            color={isSelected ? 'secondary.main' : 'text.primary'}
          >
            {title}
          </Typography>

          {endornment && (
            <ListItemIcon
              sx={{
                minWidth: 'unset',
                pl: '40px',
                mr: '0',
                color: isSelected ? 'secondary.main' : 'text.primary'
              }}
            >
              {endornment}
            </ListItemIcon>
          )}
        </ListItemButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={Mopen}
          onClose={handleMClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left'
          }}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
        >
          {NavSubMenu?.[section ? section : title]?.length > 0 &&
            NavSubMenu?.[section ? section : title]?.map((item, i) => (
              <MenuItem
                key={i}
                path={item.link}
                title={dictionary[`${item.display_name}`]}
                exactURLs={item.exactURL ? [item.exactURL] : []}
                baseURLs={item.exactURL ? [] : [item.link]}
                handleDrawerMobileClose={handleDrawerMobileClose}
                idList={idList}
                margin={'40px'}
              />
            ))}
        </Menu>
      </Box>

      {!DISPLAY_DESKTOPSUBMENU && (
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <MenuItem
            path={path}
            title={title}
            icon={icon}
            exactURLs={exactURLs}
            baseURLs={baseURLs}
            excludedURLs={excludedURLs}
            endornment={endornment}
            handleDrawerMobileClose={handleDrawerMobileClose}
            idList={idList}
          />
        </Box>
      )}
    </>
  )
}
