import List from '@mui/material/List'
import IconButton from '@mui/material/IconButton'

import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography'

// import API_URL from '@config/services'
import {
  // Button,
  CircularProgress,
  // Stack,
  Box
} from '@mui/material'
import NotifItem from './NotifItem'
import AlertList from '@components/Notifications/AlertList'
import { useTranslation } from '@contexts/translation'
import {
  Close
  // OpenInNew
} from '@mui/icons-material'
// import { markAsRead } from '@api/digests'
// import { mutate } from 'swr'
// import { useRouter } from 'next/router'
// import routes from '@config/routes'
import CommonModal from '@components/common/Modal/CommonModal'
import { useState } from 'react'
import NotifsSettingsForm from './NotifsSettings'
// import { responseHandler } from '@utils/responseHandler'
// import { useSnackbar } from 'notistack'

const NotificationsTopBar = ({
  onClose,
  notifsUnread,
  // setNotifsUnread,
  isLoadingNotifs
}) => {
  const { dictionary } = useTranslation()
  // const { enqueueSnackbar } = useSnackbar()
  // const router = useRouter()

  const [isPrefs, setIsPrefs] = useState(false)

  // const notifsURL = API_URL.DIGESTS.GET_DIGESTS_INBOX_UNREAD

  // const markNotifAsRead = async id => {
  //   setNotifsUnread && setNotifsUnread(prev => prev.filter(n => n.id !== id))
  //   const response = await markAsRead(id)

  //   responseHandler({
  //     response,
  //     callback: async () => {
  //       await mutate(notifsURL)
  //       await mutate(API_URL.DIGESTS.GET_DIGESTS_INBOX_ALL)
  //     },
  //     dictionary,
  //     snackbar: enqueueSnackbar
  //   })
  // }

  return (
    <>
      <Box
        sx={{
          bgcolor: theme => theme.palette.secondary.light,
          padding: theme => theme.spacing(2),
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant="h6" color="secondary.dark">
          {dictionary.notifications}
        </Typography>
        <IconButton
          sx={{ color: t => t.palette.secondary.dark }}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      </Box>

      <Grid container padding={2}>
        {/*
        <Grid item xs={12} mb={6}>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            
            <Button variant="outlined" onClick={() => setIsPrefs(true)}>
              {dictionary.settings}
            </Button>

            <Button
              startIcon={<OpenInNew />}
              onClick={() => router.push(routes.collaboration.notifications)}
            >
              {dictionary.notifications_center}
            </Button>
           
          </Stack>
        </Grid>
         */}
        <Grid item xs={12}>
          <Typography variant="h6">{dictionary.alerts}</Typography>
          <Grid item xs={12} marginTop={2}>
            <AlertList />
            {/* <Typography variant="subtitle1">{dictionary.no_alerts}</Typography> */}
          </Grid>
          <Typography variant="h6" sx={{ mt: 3 }}>
            {dictionary.notifications}
          </Typography>
          {isLoadingNotifs && (
            <Grid display="flex" justifyContent="center" sx={{ mt: 6, mb: 6 }}>
              <CircularProgress />
            </Grid>
          )}
          {!isLoadingNotifs && notifsUnread && !notifsUnread.length && (
            <Grid item xs={12} marginTop={3}>
              <Typography variant="subtitle1">
                {dictionary.no_unread_notif}
              </Typography>
            </Grid>
          )}
          {!isLoadingNotifs && notifsUnread && notifsUnread.length > 0 && (
            <List dense={true} sx={{ marginTop: theme => theme.spacing(1) }}>
              {notifsUnread.map(n => (
                <NotifItem
                  key={n.id}
                  notif={n}
                  secondaryAction={
                    <IconButton
                      size="small"
                      // onClick={() => markNotifAsRead(n.id)}
                    >
                      <Close fontSize="small" />
                    </IconButton>
                  }
                />
              ))}
            </List>
          )}
        </Grid>
      </Grid>
      <CommonModal
        title={dictionary.notifications_preferences}
        open={isPrefs}
        onClose={() => setIsPrefs(false)}
        maxWidth="sm"
      >
        <NotifsSettingsForm onClose={() => setIsPrefs(false)} />
      </CommonModal>
    </>
  )
}

export default NotificationsTopBar
