import { useEffect } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
// import AdapterDateFns from '@mui/lab/AdapterDateFns'
// import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ThemeProvider } from '@mui/material/styles'
import frLocale from 'date-fns/locale/fr'
import NotistackProvider from '@components/common/Notistack/NotistackProvider'
import Header from '@components/Header'
// import 'devextreme/dist/css/dx.light.css'
// import { GoogleAnalytics } from '@utils/googleAnalytics'
//import ErrorBoundary from '../components/ErrorBoundary'

import { AuthProvider, ProtectRoute } from '@contexts/auth'
import { TranslationProvider } from '@contexts/translation'

import theme from '@styles/theme/theme'

import '@styles/globals.css'
import { Analytics } from '@vercel/analytics/react'
//import ErrorBoundary from '@components/ErrorBoundary'
import '@data/i18n/i18n'

function MyApp({ Component, pageProps }) {
  const router = useRouter()
  const path = router.pathname
  const siteTitle = path.split('/')

  useEffect(() => {
    if (router.asPath.includes('undefined')) {
      router.push('/404')
    }
  }, [router])
  useEffect(() => {
    // GoogleAnalytics()

    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
  }, [])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
      <NotistackProvider>
        <AuthProvider>
          <TranslationProvider>
            <ProtectRoute>
              <ThemeProvider theme={theme}>
                {!router?.pathname?.includes('print') ? (
                  <Header>
                    <Head>
                      <title>KEYSY {siteTitle[1]}</title>
                    </Head>
                    {/* <ErrorBoundary> */}

                    <Component {...pageProps} />

                    {/* </ErrorBoundary> */}
                  </Header>
                ) : (
                  <>
                    <Head>
                      <title>KEYSY {siteTitle[1]}</title>
                    </Head>
                    <Component {...pageProps} />
                    <Analytics />
                  </>
                )}
              </ThemeProvider>
            </ProtectRoute>
          </TranslationProvider>
        </AuthProvider>
      </NotistackProvider>
    </LocalizationProvider>
  )
}

export default MyApp
